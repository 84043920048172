.table-row {
  color: black;
  display: flex;
  text-align: left;
  font-size: 14px;
}

.table-data {
  flex: 1;
  align-items: center;
  border: 0.5px solid #c9c9c9;
  border-bottom: none;
  padding: 10px;
}
